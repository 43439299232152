import { useRef } from "react"
import { QRCode } from "antd"
import BaseModal from "../../../components/layout/BaseModal"
import { ReactComponent as QRCODE } from "../../../assets/QRCodeICON.svg"
import { ReactComponent as REFRESH } from "../../../assets/RefreshICON.svg"
import { ReactComponent as DOWNLOAD } from "../../../assets/DownloadICON.svg"
type QrCodeModalProps = {
    modalId: number
    qrcode?: string
    generateQRcode: () => void
    statusQRCodeGeneration: string
    adress: string
}

// Utility function to download a URL as a file
function doDownload(url: string, fileName: string) {
    const a = document.createElement("a")
    a.download = fileName
    a.href = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

// Function to download the QR code as an image
const downloadCanvasQRCode = () => {
    const canvas = document.getElementById("myqrcode")?.querySelector<HTMLCanvasElement>("canvas")
    if (canvas) {
        const url = canvas.toDataURL("image/png")
        doDownload(url, "QRCode.png")
    }
}

export default function QrCodeModal(props: QrCodeModalProps) {
    return (
        <BaseModal modalId={props.modalId} title={"QR Code"} size>
            {props.qrcode ? (
                <div className="flex flex-col justify-center items-center p-8 gap-3">
                    <div id="myqrcode">
                        <QRCode errorLevel="H" value={props.qrcode} icon="/besitzwerk-icon.png" className="w-48 h-48" />
                    </div>
                    <button className="btn btn-outline btn-primary w-full" onClick={props.generateQRcode}>
                        Neu Generieren <REFRESH className="w-6 h-6" />
                    </button>
                    <button className="btn btn-outline btn-primary w-full" onClick={downloadCanvasQRCode}>
                        Herunterladen <DOWNLOAD className="w-6 h-6" />
                    </button>
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center p-8">
                    <p className="text-center pb-4">Für dieses Objekt wurde noch kein QR Code generiert.</p>
                    <button className="btn btn-outline btn-primary" onClick={props.generateQRcode}>
                        Generieren <QRCODE className="w-8 h-8" />
                    </button>
                </div>
            )}
        </BaseModal>
    )
}
