import { useQuery } from "@tanstack/react-query"
import ErrorPage from "../../../404/ErrorPage"
import Loading from "../../../../components/common/Loading"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import { TransactionsType } from "../../types"
import { TransactionElement } from "./TransactionElement"
import { getPaginatedData } from "../../../api/apiCalls"
import { Pagination } from "antd"
import { useDelayWhenSearchWithPagination } from "../../../../services/functions"
import { defaultTransactionStatus } from "../../../../data/defaultValues"
import BaseSearchInputField from "../../../../components/elements/BaseSearchInputField"
import { ReactComponent as RELOAD } from "../../../../assets/RefreshICON.svg"

export default function Transactions(props: TransactionsType) {
    
    const displayedTransactions = useMemo(() => props.data?.docs, [props.data?.docs])

    return (
        <section className="w-full max-w-[50%] bg-white rounded-default shadow-lg grow h-[85vh] mt-4 p-4">
            <div className="border-b pb-2 flex justify-between">
                <h3 className="text-xl font-semibold">Nicht zugeordnete Zahlungen</h3>
                <button className="btn btn-sm btn-primary mr-2" onClick={props.refreshTransactions}>
                                            <RELOAD className="h-6 w-6" />
                                        </button>
            </div>
            <div className="overflow-y-scroll flex flex-col h-[94%] overflow-x-hidden">
                    <>
                        <BaseSearchInputField
                            small
                            placeholder="Nach Verwendungszweck/Preis suchen"
                            searchFunction={props.setsearchTerm}
                            value={props.searchTerm}
                            classProps="my-2"
                        />
                        {displayedTransactions?.map((item: any, index: number) => (
                            <TransactionElement
                                key={index}
                                amount={item.paymentInformation.transactionAmount.amount}
                                date={dayjs(item.bookingTimestamp).format("DD.MM.YYYY")}
                                note={item.paymentInformation.remittanceText}
                                status={item.processingStatus}
                                openModal={() => {
                                    props.openModal()
                                    props.func(item)
                                }}
                            />
                        ))}
                        <div className="flex justify-center w-full p-4">
                            <Pagination
                                defaultCurrent={1}
                                current={props.page}
                                pageSize={15}
                                onChange={props.handlePageChange}
                                total={props.data.totalDocs}
                                showSizeChanger={false}
                            />
                        </div>
                    </>

            </div>
        </section>
    )
}
