import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { useContext, useEffect, useMemo, useState } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import { useNavigate } from "react-router-dom"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { PR } from "../../../data/Types"
import PaymentTableRow from "../components/payment/PaymentTableRow"
import RecheckPaymentModal from "../components/payment/paymentRecheckModal/RecheckPaymentModal"
import SolveEscaltionModal from "../components/payment/solveEscaltion/SolveEscaltionModal"
import { useMarkPayreqAsPaidManual, useSolveEscalationMutation } from "../api/services"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"
import BaseFilterModal from "../../../components/elements/BaseFilterModal"
import { ReactComponent as OFFER } from "../../../assets/OfferIcon.svg"
import { ReactComponent as BILL } from "../../../assets/BillIcon.svg"
import { ReactComponent as ARROWUP } from "../../../assets/ArrowUpIcon.svg"
import { ReactComponent as ARROWDOWN } from "../../../assets/ArrowDownIcon.svg"

export default function PaymentPage() {
    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination

    const [searchTerm, setsearchTerm] = useState("")
    const [status, setstatus] = useState("")
    const [invoiceStartDate, setinvoiceStartDate] = useState("")
    const [invoiceEndDate, setinvoiceEndDate] = useState("")
    const [invertSort, setinvertSort] = useState(false)
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    // Get Data Query
    const queryKeyPayreqs = "paymentsData" + page + searchParams + status + invoiceStartDate + invoiceEndDate + invertSort
    const { error, data, isLoading } = useQuery({
        queryKey: [queryKeyPayreqs],
        queryFn: () =>
            getPaginatedData({
                pageType: "paymentrequirements",
                page: page,
                limit: 15,
                search: searchParams,
                status: status,
                startDate: invoiceStartDate,
                endDate: invoiceEndDate,
                invertSort: invertSort,
            }),
    })

    const navigate = useNavigate()
    const modalCon = useContext(ModalContext)
    const [payment, setpayment] = useState<PR>()

    useEffect(() => {
        if (payment) {
            setpayment(data?.docs.find((item: { _id: string }) => item._id === payment._id))
        }
    }, [data?.docs])

    // NOTE -> used in work with Filter Modal
    const [showFilter, setshowFilter] = useState(false)

    const queryClient = useQueryClient()
    const [solvingId, setSolvingId] = useState("")
    const { mutate: solveEscalationFunction } = useSolveEscalationMutation(queryClient, modalCon, 1, queryKeyPayreqs)
    const solveEscalation = () => {
        solveEscalationFunction(solvingId)
    }

    const handleRemoveFilter = () => {
        setsearchTerm("")
        setstatus("")
        setinvoiceEndDate("")
        setinvoiceStartDate("")
    }

    const displayedPayReqs = useMemo(() => {
        if (data?.docs) {
            return data.docs
        }
    }, [data?.docs])

    const { mutate: markAsPaid } = useMarkPayreqAsPaidManual(queryClient, queryKeyPayreqs)
    return (
        <BasePage>
            <HeadBanner title={"Zahlungsaufforderungen"} />
            {/* UPPER ROW OF INPUT & BUTTON */}
            <section className="w-full flex flex-wrap justify-between gap-4 py-4 md:px-4 items-center relative max-h-[18vh]">
                <div className="flex gap-4 w-full sm:w-fit">
                    <input
                        onKeyDown={preventKeyDown}
                        onChange={(e) => setsearchTerm(e.target.value)}
                        type="text"
                        value={searchTerm ?? null}
                        placeholder="Rechnungsnummer / Kunde suchen..."
                        className="w-full sm:w-80 rounded-default bg-none shadow-lg p-4 text-base-200"
                    />
                </div>
                <div className="flex flex-row gap-4">
                    <button className="btn bg-white shadow-lg border-none hover:bg-white/40" onClick={() => navigate("/buchhaltung/angebote")}>
                        <OFFER className="w-6 h-6" />
                        Angebote
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => navigate("/buchhaltung/rechnungen")}>
                        <BILL className="w-6 h-6" />
                        Rechnungen
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => {
                            setinvertSort(!invertSort)
                        }}>
                        {invertSort ? <ARROWUP className="w-4 h-4" /> : <ARROWDOWN className="w-4 h-4" />}
                        Sortieren
                    </button>
                    <BaseFilterButton func={() => setshowFilter(!showFilter)} />
                    <BaseFilterModal
                        visible={showFilter}
                        searchdateSince={invoiceStartDate}
                        searchdateTo={invoiceEndDate}
                        changeDateSince={setinvoiceStartDate}
                        changeDateTo={setinvoiceEndDate}
                        searchStatus={status}
                        changeStatus={setstatus}
                        statusType={"payreq"}
                        removeFilter={handleRemoveFilter}
                        setVisible={() => setshowFilter(!showFilter)}
                    />
                </div>
            </section>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !error && !isLoading && (
                <>
                    {/* TABLE */}
                    <section className="w-full md:px-4 h-[65vh] xl:max-h-[75vh] overflow-x-hidden">
                        <table className="table table-md table-pin-rows bg-white shadow-lg">
                            <thead>
                                <tr className=" h-12 bg-gray-200 border-none">
                                    <th className=" text-base sm:w-12 h-full items-center justify-center"></th>
                                    <th className=" hidden lg:table-cell text-base min-w-[10rem] w-fit text-center">Fälligkeitsdatum</th>
                                    <th className=" text-base w-fit text-center truncate">{window.innerWidth > 640 ? "Rechnungsnummer" : "Nr."}</th>
                                    <th className=" text-base sm:min-w-[8rem] w-fit text-center">Netto</th>
                                    <th className=" text-base sm:min-w-[8rem] w-fit text-center">Brutto</th>
                                    <th className="hidden md:table-cell text-base w-fit text-center">Kunde</th>
                                    <th className="hidden xl:table-cell text-base min-w-[10rem] w-fit text-center">Status</th>
                                    <th className="w-fit"></th>
                                    <th className="w-fit"></th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll">
                                {displayedPayReqs?.map((item: PR) => (
                                    <PaymentTableRow
                                        key={item?._id}
                                        id={item?.bill_id?._id}
                                        title={item?.bill_id?.nr}
                                        customer={item?.customer_id?.customer?.name}
                                        status={item?.status}
                                        date={item?.invoice_date}
                                        delivery={item?.due_date}
                                        netto={item?.totalNetto}
                                        brutto={item?.expected_payment}
                                        type={item?.bill_id?.billType}
                                        archived={false}
                                        escalationRequired={item?.escalationRequired}
                                        openSolveEscalationModal={() => {
                                            setSolvingId(item._id)
                                            modalCon?.trigger(1)
                                        }}
                                        modalOpen={() => {
                                            setpayment(item)
                                            modalCon?.trigger(2)
                                        }}
                                        markAsPaid={() => markAsPaid(item._id)}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {(!displayedPayReqs || displayedPayReqs.length === 0) && (
                            <p className="text-center text-gray-300 uppercase font-medium pt-4">Keine erwarteten Zahlungen</p>
                        )}
                    </section>
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            defaultCurrent={1}
                            current={page}
                            pageSize={15}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            )}
            <SolveEscaltionModal modalId={1} solveEscalation={solveEscalation} />
            <RecheckPaymentModal
                modalId={2}
                payment={payment}
                refreshDataPayment={"paymentsData" + page + searchParams + status + invoiceStartDate + invoiceEndDate + invertSort}
            />
        </BasePage>
    )
}
