import BaseSelection from "../../../../../components/elements/BaseSelection"
import { Position } from "../../../../../data/Types"
import { preventKeyDown } from "../../../../../services/functions"

export default function OfferPositions({ description, amount, singlePrice, tax, unit, id, remove, index, onChange }: Position) {
    return (
        <div className="flex flex-col w-full gap-1 px-4 pb-4 pt-2 rounded-default border relative mt-3">
            <div className="flex flex-row justify-between px-4 border-b py-2 mb-2">
                <p className="pl-0">Position {index + 1}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => remove(index)}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
            <div className="flex flex-row w-full gap-4">
                <div className={`flex flex-col w-48`}>
                    <label className="text-sm pl-2 pb-1">Menge *</label>
                    <input
                        required
                        onChange={(e) => {
                            onChange(index, "amount", e.target.value)
                        }}
                        type="number"
                        value={amount}
                        className="border p-4 rounded-default w-full bg-none"
                    />
                </div>
                <div className={`flex flex-col w-full`}>
                    <label className="text-sm pl-2 pb-1">Einheit *</label>
                    <input
                        required
                        onChange={(e) => {
                            onChange(index, "unit", e.target.value)
                        }}
                        type="text"
                        value={unit}
                        className="border p-4 rounded-default w-full bg-none"
                    />
                </div>
                <div className="flex flex-col w-full">
                    <BaseSelection
                        setId={(e) => {
                            if (e === "19% MwSt.") {
                                onChange(index, "tax", "19")
                            } else {
                                onChange(index, "tax", "7")
                            }
                        }}
                        data={["19% MwSt.", "7% MwSt."]}
                        label={"Abrechnung *"}
                        placeholder={"Bitte auswählen"}
                        value={tax + (tax && "%")}
                    />
                </div>
            </div>
            <div className="flex flex-row w-full gap-4 justify-end">
                <div className={`flex flex-col w-full`}>
                    <label className="text-sm pl-2 pb-1">Beschreibung *</label>
                    <input
                        required
                        onChange={(e) => {
                            onChange(index, "description", e.target.value)
                        }}
                        type="text"
                        value={description}
                        className="border p-4 rounded-default w-full bg-none"
                    />
                </div>
                <div className={`flex flex-col w-48`}>
                    <label className="text-sm pl-2 pb-1">Einzelpreis (€) *</label>
                    <input
                        onKeyDown={preventKeyDown}
                        required
                        onChange={(e) => {
                            onChange(index, "singlePrice", e.target.value)
                        }}
                        type="number"
                        value={singlePrice}
                        className="border p-4 rounded-default w-full bg-none"
                    />
                </div>
                <div className="flex flex-col justify-between w-48 items-end">
                    <label className="text-sm pl-2 pb-1">Gesamt</label>
                    <p className="font-semibold text-lg">{(singlePrice * amount).toFixed(2).replace(".", ",")} €</p>
                </div>
            </div>
        </div>
    )
}
