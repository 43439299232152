import { useContext, useEffect, useMemo, useState } from "react"
import BasePage from "../../../components/layout/BasePage"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import { getCustomerObjectData, getSingleCustomerData } from "../api/apiCalls"
import { useParams } from "react-router-dom"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import { Customer } from "../types/Types"
import { useAddDocToCustomerMutation, usePatchDocCustomerMutation, useUpdateCustomerMutation } from "../api/services"
import { checkForEmptyValues, compareValues, formatCurrency, validateEmail } from "../../../services/functions"
import ModalCheckAction from "../../../components/elements/ModalCheckAction"
import { useDownloadDocumentMutation } from "../../api/services"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseAddDocumentModal from "../../../components/elements/BaseAddDocumentModal"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import CustomerDetailPartLeft from "./customerDetail/CustomerDetailPartLeft"
import CustomerDetailPartRight from "./customerDetail/CustomerDetailPartRight"
import BasePreview from "../../../components/elements/BasePreview"
import { useResendMailMutation } from "../../../services/api/services"
import { API } from "../../../api/constants"
import { messageInfo } from "../../../services/messages"

export default function CustomerDetailPage() {
    const customerId = useParams()

    const { data, error, isLoading } = useQuery({
        queryKey: ["singleCustomerData" + customerId.id],
        queryFn: () => getSingleCustomerData(customerId.id ?? ""),
    })
    const {
        data: dataCustomerObj,
        error: errorCustomerObj,
        isLoading: isloadingCustomerObj,
    } = useQuery({
        queryKey: ["singleCustomerObjData" + customerId.id],
        queryFn: () => getCustomerObjectData(customerId.id ?? ""),
    })

    const [cname, setcname] = useState(data?.customer?.customer?.name)
    const [ctel, setctel] = useState(data?.customer?.customer?.tel)
    const [cmail, setcmail] = useState(data?.customer?.customer?.mail)
    const [cperson, setcperson] = useState(data?.customer?.customer?.person)
    const [ccomment, setccomment] = useState(data?.customer?.customer?.comment)
    const [creceiver, setcreceiver] = useState(data?.customer?.administrator?.receivesMails)
    const [cadress, setcadress] = useState(data?.customer?.customer?.adress)
    const [aname, setaname] = useState(data?.customer?.administrator?.name)
    const [atel, setatel] = useState(data?.customer?.administrator?.tel)
    const [amail, setamail] = useState(data?.customer?.administrator?.mail)
    const [aperson, setaperson] = useState(data?.customer?.administrator?.person)
    const [acomment, setacomment] = useState(data?.customer?.administrator?.comment)
    const [areceiver, setareceiver] = useState(data?.customer?.administrator?.receivesMails)
    const [aadress, setaadress] = useState(data?.customer?.administrator?.adress)
    const [ranking, setranking] = useState(data?.customer?.ranking)
    const [isAdministratorDocumentReceiver, setisAdministratorDocumentReceiver] = useState<boolean>(
        data?.customer?.isAdministratorDocumentReceiver ?? false
    )
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)

    // add file
    const { mutate: addDoc } = useAddDocToCustomerMutation(queryClient)

    // download file from customer
    const { mutate: downloadFile } = useDownloadDocumentMutation()
    const handleDownload = (s3Key: string, filename: string) => {
        downloadFile({ id: data.customer._id, s3Key: s3Key, filetype: "customer", filename: filename })
    }

    // delete file
    const [fileToDelete, setfileToDelete] = useState<string>("")
    const { mutate: deleteDoc } = usePatchDocCustomerMutation(queryClient, modalCon, 1)
    const handleDeleteDoc = () => {
        if (data.softDeleteAfter) {
            messageInfo(
                "1a2b3c",
                "Das klappt gerade nicht!",
                'Dieser Kunden ist als "gelöscht" markiert und kann deswegen nicht mehr bearbeitet werden. Stellen Sie den Kunden erst wieder her, bevor zu ihn bearbeiten.',
                10
            )
        } else {
            deleteDoc({
                id: customerId.id ?? "",
                s3Key: fileToDelete,
            })
        }
    }

    // get preview of pdf file
    const [selectedS3Key, setselectedS3Key] = useState<string>()
    const customerDocDownloadUrl = useMemo(() => {
        return error || isLoading || !selectedS3Key ? undefined : API + `/customer/${data?.customer._id}/download?s3Key=${selectedS3Key}`
    }, [data, error, isLoading, selectedS3Key])
    // copy preview data to avoid double using arrayBuffer

    // initial set
    useEffect(() => {
        if (data) {
            console.log(data.customer.isAdministratorDocumentReceiver)
            setcname(data?.customer?.customer?.name)
            setctel(data?.customer?.customer?.tel)
            setcperson(data?.customer?.customer?.person)
            setcmail(data?.customer?.customer?.mail)
            setccomment(data?.customer?.customer?.comment)
            setcreceiver(data?.customer?.customer?.receivesMails)
            setcadress(data?.customer?.customer?.adress)
            setaname(data?.customer?.administrator?.name)
            setatel(data?.customer?.administrator?.tel)
            setaperson(data?.customer?.administrator?.person)
            setamail(data?.customer?.administrator?.mail)
            setacomment(data?.customer?.administrator?.comment)
            setareceiver(data?.customer?.administrator?.receivesMails)
            setaadress(data?.customer?.administrator?.adress)
            setranking(data?.customer?.ranking)
            setisAdministratorDocumentReceiver(data?.customer?.isAdministratorDocumentReceiver ? true : false)
        }
    }, [data])

    // update selected customer
    const { mutate: updateCustomerQuery } = useUpdateCustomerMutation(queryClient)
    const handleUpdateCustomer = () => {
        const updateCustomerData: Customer = {
            customer: {
                name: cname ?? data?.customer?.customer?.name,
                tel: ctel ?? data?.customer?.customer?.tel,
                person: cperson ?? data?.customer?.customer?.person,
                mail: cmail ?? data?.customer?.customer?.mail,
                comment: ccomment ?? data?.customer?.customer?.comment,
                adress: cadress ?? data?.customer?.customer?.adress,
                receivesMails: creceiver ?? data?.customer?.customer?.receivesMails,
            },
            administrator: {
                name: aname ?? data?.customer?.administrator?.name,
                tel: atel ?? data?.customer?.administrator?.tel,
                person: aperson ?? data?.customer?.administrator?.person,
                mail: amail ?? data?.customer?.administrator?.mail,
                comment: acomment ?? data?.customer?.administrator?.comment,
                adress: aadress ?? data?.customer?.administrator?.adress,
                receivesMails: areceiver ?? data?.customer?.administrator?.receivesMails,
            },
            ranking: ranking ?? data?.customer?.ranking,
            isAdministratorDocumentReceiver: isAdministratorDocumentReceiver,
        }
        if (data.customer.softDeleteAfter) {
            messageInfo(
                "1a2b3c",
                "Das klappt gerade nicht!",
                'Dieser Kunden ist als "gelöscht" markiert und kann deswegen nicht mehr bearbeitet werden. Stellen Sie den Kunden erst wieder her, bevor zu ihn bearbeiten.',
                10
            )
        } else {
            updateCustomerQuery({
                id: customerId.id ?? "",
                body: updateCustomerData,
            })
        }
    }

    // mail Preview for SidePanel
    const [mailPreviewContent, setMailPreviewContent] = useState<string>()
    const [resendUri, setResendUri] = useState<string>()
    const { mutate: resendMailFunction } = useResendMailMutation()

    return (
        <BasePage>
            <BaseHeadBanner
                title={
                    "Kunde " +
                    (cname ?? data?.customer?.customer?.name) +
                    " | Nummer: " +
                    (data?.customer?.debtorIdentifier ?? "#404") +
                    " | Saldo: " +
                    (formatCurrency(data?.customer?.saldo) ?? "#404")
                }
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.kunden_read_write]}>
                        <div className="hidden md:flex flex-row w-fit items-center gap-2">
                            <input
                                type="radio"
                                name="radio-5"
                                value={1}
                                className="radio radio-success"
                                checked={(ranking ?? data?.customer?.ranking) === 1}
                                onChange={(e) => setranking(Number(e.target.value))}
                            />
                            <input
                                type="radio"
                                name="radio-5"
                                value={2}
                                className="radio radio-warning"
                                checked={(ranking ?? data?.customer?.ranking) === 2}
                                onChange={(e) => setranking(Number(e.target.value))}
                            />
                            <input
                                type="radio"
                                name="radio-5"
                                value={3}
                                className="radio radio-error"
                                checked={(ranking ?? data?.customer?.ranking) === 3}
                                onChange={(e) => setranking(Number(e.target.value))}
                            />
                            <button
                                disabled={
                                    !validateEmail(amail) ||
                                    !validateEmail(cmail) ||
                                    checkForEmptyValues([cname, aname, ctel, atel, cmail, amail, cadress, aadress]) ||
                                    !compareValues([
                                        {
                                            v1: ranking,
                                            v2: data?.customer?.ranking,
                                        },
                                        {
                                            v1: cname,
                                            v2: data?.customer?.customer?.name,
                                        },
                                        {
                                            v1: ctel,
                                            v2: data?.customer?.customer?.tel,
                                        },
                                        {
                                            v1: cperson,
                                            v2: data?.customer?.customer?.person,
                                        },
                                        {
                                            v1: cmail,
                                            v2: data?.customer?.customer?.mail,
                                        },
                                        {
                                            v1: ccomment,
                                            v2: data?.customer?.customer?.comment,
                                        },
                                        {
                                            v1: cadress,
                                            v2: data?.customer?.customer?.adress,
                                        },
                                        {
                                            v1: creceiver,
                                            v2: data?.customer?.customer?.receivesMails,
                                        },
                                        {
                                            v1: aname,
                                            v2: data?.customer?.administrator?.name,
                                        },
                                        {
                                            v1: atel,
                                            v2: data?.customer?.administrator?.tel,
                                        },
                                        {
                                            v1: aperson,
                                            v2: data?.customer?.administrator?.person,
                                        },
                                        {
                                            v1: amail,
                                            v2: data?.customer?.administrator?.mail,
                                        },
                                        {
                                            v1: acomment,
                                            v2: data?.customer?.administrator?.comment,
                                        },
                                        {
                                            v1: aadress,
                                            v2: data?.customer?.administrator?.adress,
                                        },
                                        {
                                            v1: areceiver,
                                            v2: data?.customer?.administrator?.receivesMails,
                                        },
                                        {
                                            v1: isAdministratorDocumentReceiver ? true : false,
                                            v2: data?.customer?.isAdministratorDocumentReceiver ?? false,
                                        },
                                    ])
                                }
                                className="btn btn-success ml-4"
                                onClick={handleUpdateCustomer}>
                                Speichern
                            </button>
                        </div>
                    </RenderWhenAuthorized>
                }
            />
            {(error || errorCustomerObj) && <ErrorPage />}
            {(isLoading || isloadingCustomerObj) && <Loading />}
            {data && dataCustomerObj && (
                <>
                    <section className="flex flex-col lg:flex-row w-full gap-4 pt-4 overflow-y-scroll">
                        {/* LEFT */}
                        <CustomerDetailPartLeft
                            cname={cname}
                            ctel={ctel}
                            cperson={cperson}
                            cmail={cmail}
                            ccomment={ccomment}
                            creceiver={creceiver}
                            cadress={cadress}
                            setcname={setcname}
                            setctel={setctel}
                            setcperson={setcperson}
                            setcmail={setcmail}
                            setccomment={setccomment}
                            setcreceiver={setcreceiver}
                            setcadress={setcadress}
                            aname={aname}
                            atel={atel}
                            aperson={aperson}
                            amail={amail}
                            acomment={acomment}
                            areceiver={areceiver}
                            aadress={aadress}
                            setaname={setaname}
                            setatel={setatel}
                            setaperson={setaperson}
                            setamail={setamail}
                            setacomment={setacomment}
                            setareceiver={setareceiver}
                            setaadress={setaadress}
                            data={data}
                            setfileToDelete={setfileToDelete}
                            handleDownload={handleDownload}
                            handleUpdateCustomer={handleUpdateCustomer}
                            setselectedS3Key={setselectedS3Key}
                            mailReports={data.customer.mailReports}
                            setResendUri={setResendUri}
                            setMailPreviewContent={setMailPreviewContent}
                            isAdministratorDocumentReceiver={isAdministratorDocumentReceiver}
                            setisAdministratorDocumentReceiver={setisAdministratorDocumentReceiver}
                        />
                        {/* RIGHT */}
                        <CustomerDetailPartRight data={dataCustomerObj} />
                    </section>
                    <ModalCheckAction
                        modalId={1}
                        headline={"Dokument löschen?"}
                        text={"Sind Sie sich sicher, dass Sie dieses Dokument wollen?"}
                        buttonText={"Löschen"}
                        buttonColor="btn-error"
                        func={handleDeleteDoc}
                    />
                    <BaseAddDocumentModal addDoc={addDoc} closeModal={() => modalCon?.trigger(2)} modalId={2} selectedId={customerId.id ?? ""} />
                    <BasePreview title={"document"} sidePanelId={3} previewType={"document"} content={customerDocDownloadUrl} />
                    <BasePreview
                        title={"Mailvorschau"}
                        sidePanelId={4}
                        previewType={"mail"}
                        content={mailPreviewContent ?? ""}
                        resendFunction={() => resendMailFunction({ path: resendUri ?? "" })}
                    />
                </>
            )}
        </BasePage>
    )
}
