import BaseModal from "../../../components/layout/BaseModal"
import { QrReader } from "react-qr-reader"
import { useState } from "react"

type ScanQrCodeModalProps = {
    modalId: number
    startScanner: boolean
    setstartScanner: () => void
}

export default function ScanQrCodeModal(props: ScanQrCodeModalProps) {
    const [scanResult, setScanResult] = useState<any>("No result")
    const handleScan = (data: any) => {
        if (data) {
            setScanResult(data.text)
            props.setstartScanner() // Stop scanning once a result is obtained
        }
    }
    return (
        <BaseModal modalId={props.modalId} title={"QR Code"} size>
            {props.startScanner && (
                <div className="w-80">
                    <QrReader scanDelay={300} onResult={handleScan} className="w-full" constraints={{ facingMode: "front" }} />
                </div>
            )}
            <p>{scanResult}</p>
        </BaseModal>
    )
}
