import { PositionData, BillGet } from "../../../../../data/Types"
import TableRowDetailPage from "../../../components/detailBillPage/TableRowDetailPage"

type BillDetailBillPartType = {
    data: BillGet
    positions: PositionData[]
    netto: number
    brutto: number
}

export default function BillDetailBillPart(props: BillDetailBillPartType) {
    return (
        <div className="mt-4 w-full h-fit max-h-[32vh] rounded-default shadow-lg bg-white p-4 flex flex-col">
            <div className="flex flex-row justify-end border-black border-b-2 pb-2 gap-4 pr-8">
                <p>Netto: {props.netto.toFixed(2).toString().replace(".", ",")} €</p>
                <div>|</div>
                <h3 className="tracking-tight font-semibold">Brutto: {props.brutto.toFixed(2).toString().replace(".", ",")}€</h3>
            </div>
            <div className="overflow-x-hidden mt-2">
                <table className="table table-sm table-pin-rows overflow-x-scroll sm:overflow-none">
                    <thead>
                        <tr className="h-10 border-none">
                            <th className="">Position</th>
                            <th className="hidden sm:table-cell">Beschreibung</th>
                            <th className="text-center">Menge</th>
                            <th className="text-center hidden sm:table-cell">Einheit</th>
                            <th className="text-center">Einzelpreis</th>
                            <th className="text-center hidden sm:table-cell">Abrechnung</th>
                        </tr>
                    </thead>
                    <tbody className="overflow-y-scroll">
                        {props.positions?.map((item: any, index: number) => (
                            <TableRowDetailPage
                                key={index}
                                pos={(index + 1).toString()}
                                description={item.description}
                                descriptionDetail={item.descriptionSmall}
                                amount={item.amount}
                                unit={item.unit}
                                singleprice={item.singlePrice}
                                tax={item.tax}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
