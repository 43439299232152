import dayjs from "dayjs"
import BaseModal from "../../../../../components/layout/BaseModal"
import { Key, useMemo, useState } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useIgnoreTransactionMutation, useUpdateTransactionMutation, useUpdateTransactionPullMutation } from "../../../../Dashboard/api/services"
import ErrorPage from "../../../../404/ErrorPage"
import Loading from "../../../../../components/common/Loading"
import { transactionElementType } from "../../../../Dashboard/types"
import { AssignBillElement } from "./AssignBillElement"
import { TransactionRow } from "./TransactionRow"
import { RecheckPaymentModalType } from "../../../types"
import BaseSearchInputField from "../../../../../components/elements/BaseSearchInputField"
import { getPaginatedData } from "../../../../api/apiCalls"
import { Pagination } from "antd"
import { useDelayWhenSearchWithPagination } from "../../../../../services/functions"
import BaseCheckbox from "../../../../../components/elements/BaseCheckbox"
import { defaultTransactionStatus } from "../../../../../data/defaultValues"
import { getAssignedTransactionsOfPayment } from "../../../api/apiCalls"
import { ReactComponent as RELOAD } from "../../../../../assets/RefreshICON.svg"
import { useRefetchTransactions } from "../../../api/services"

export default function RecheckPaymentModal(props: RecheckPaymentModalType) {
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }
    const [searchTerm, setsearchTerm] = useState("")
    const [searchStatus, setsearchStatus] = useState<(typeof defaultTransactionStatus)[keyof typeof defaultTransactionStatus]>("UNASSIGNED")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const queryKey = "transactionData" + page + searchParams + searchStatus
    const { data, isLoading, error } = useQuery({
        queryKey: [queryKey],
        queryFn: () => getPaginatedData({ pageType: "banking", page: page, search: searchParams, limit: 15, status: searchStatus }),
    })

    const queryClient = useQueryClient()
    const { mutate: refreshTransactions } = useRefetchTransactions(queryClient, queryKey)

    const {
        data: assignedData,
        isLoading: assignedLoading,
        error: assignedError,
    } = useQuery({
        queryKey: ["assignedTransactionData" + props.payment?._id + props.payment?.transactions],
        queryFn: () => getAssignedTransactionsOfPayment(props.payment?.transactions ?? []),
        enabled: !!props.payment?.transactions,
    })

    const [furtherPayment, setfurtherPayment] = useState(false)
    const { mutate: assignTransaction } = useUpdateTransactionMutation(
        queryClient,
        props.refreshDataPayment,
        "transactionData" + page + searchParams + searchStatus
    )
    const handleAssignTransactionToPayreq = (tid: string) => {
        assignTransaction({
            pid: props.payment?._id ?? "",
            tid: tid,
        })
    }
    const { mutate: unassignTransaction } = useUpdateTransactionPullMutation(
        queryClient,
        props.refreshDataPayment,
        "transactionData" + page + searchParams + searchStatus
    )
    const handleUnassignTransactionFromPayreq = (tid: string) => {
        unassignTransaction({
            pid: props.payment?._id ?? "",
            tid: tid,
        })
    }
    const { mutate: ignoreTransaction } = useIgnoreTransactionMutation(queryClient, "transactionData" + page + searchParams + searchStatus)
    const handleIgnoreTransaction = (tid: string) => {
        ignoreTransaction(tid)
    }

    const displayedTransactions = useMemo(() => data?.docs, [data?.docs])

    return (
        <BaseModal title="Zahlungen verwalten" modalId={props.modalId}>
            {error && <ErrorPage />}
            {(isLoading || assignedLoading) && <Loading />}
            {props.payment && data && !error && !isLoading && (
                <div className="flex flex-col">
                    {/* Basic Information */}
                    <div className="flex flex-row gap-8 p-4 border-b w-full">
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">Zahlung für</p>
                            <p className="font-medium">Rechnung {props.payment?.bill_id?.nr}</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">Typ</p>
                            <p className="font-medium">{props.payment.bill_id?.billType}</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">Betrag</p>
                            <p className="font-medium">{props.payment.expected_payment.toString().replace(".", ",")} €</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">ausgestellt am</p>
                            <p className={`font-medium`}>{dayjs(props.payment.invoice_date).format("DD.MM.YYYY")}</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">fällig am</p>
                            <p className={`font-medium`}>{dayjs(props.payment.due_date).format("DD.MM.YYYY")}</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">Status</p>
                            <p className={`font-medium ${props.payment.status === "settled" ? "text-green-600" : "text-red-600"}`}>
                                {props.payment.status === "settled" ? "Beglichen" : "Offen"}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col p-4 border-b w-full">
                        {assignedData?.length === 0 && <p className="text-center font-medium">Keine Zahlung zugeordnet</p>}
                        {assignedError && <ErrorPage />}
                        {assignedData &&
                            assignedData?.map((item: transactionElementType, index: number) => (
                                <TransactionRow transaction={item} key={index} remove={() => handleUnassignTransactionFromPayreq(item._id)} />
                            ))}
                    </div>
                    {!furtherPayment && (
                        <button className="btn btn-primary btn-sm mt-2" onClick={() => setfurtherPayment(!furtherPayment)}>
                            Weitere Transaktion zuweisen
                        </button>
                    )}
                    {furtherPayment && (
                        <>
                            <div className="flex flex-col w-full pt-2 py-2">
                                <div className="flex justify-between w-full">
                                    <BaseSearchInputField
                                        small
                                        placeholder="Nach Verwendungszweck/Preis suchen"
                                        searchFunction={setsearchTerm}
                                        value={searchTerm}
                                    />
                                    <div className="flex items-center">
                                        <button className="btn btn-sm btn-primary mr-2" onClick={() => refreshTransactions()}>
                                            <RELOAD className="h-6 w-6" />
                                        </button>
                                        <button className="btn btn-sm btn-primary mr-2" onClick={() => setfurtherPayment(!furtherPayment)}>
                                            Abbrechen
                                        </button>
                                    </div>
                                </div>
                                <div className="flex justify-between py-2 gap-2">
                                    <BaseCheckbox
                                        check={searchStatus === defaultTransactionStatus.UNASSIGNED}
                                        setcheck={() => setsearchStatus(defaultTransactionStatus.UNASSIGNED)}
                                        label={"Nicht Zugeordnete Transaktionen anzeigen"}
                                    />
                                    <BaseCheckbox
                                        check={searchStatus === defaultTransactionStatus.ASSIGNED}
                                        setcheck={() => setsearchStatus(defaultTransactionStatus.ASSIGNED)}
                                        label={"Zugeordnete Transaktionen anzeigen"}
                                    />
                                    <BaseCheckbox
                                        check={searchStatus === defaultTransactionStatus.IGNORE}
                                        setcheck={() => setsearchStatus(defaultTransactionStatus.IGNORE)}
                                        label={"Ignorierte Transaktionen anzeigen"}
                                    />
                                </div>
                            </div>
                            <div className="overflow-hidden">
                                {displayedTransactions?.map(
                                    (
                                        item: {
                                            _id: string
                                            processingStatus: string
                                            bookingTimestamp: string | number | Date | dayjs.Dayjs | null | undefined
                                            paymentInformation: { transactionAmount: { amount: string }; remittanceText: string }
                                        },
                                        index: Key | null | undefined
                                    ) => (
                                        <AssignBillElement
                                            unassignTransaction={() => handleUnassignTransactionFromPayreq(item._id)}
                                            assignTransaction={() => handleAssignTransactionToPayreq(item._id)}
                                            ignoreTransaction={() => handleIgnoreTransaction(item._id)}
                                            key={index}
                                            alreadyAssignedToPayreq={props.payment?.transactions?.includes(item._id) ?? false}
                                            date={dayjs(item.bookingTimestamp).format("DD.MM.YYYY")}
                                            information={item}
                                        />
                                    )
                                )}
                            </div>
                            <div className="flex justify-center w-full p-4">
                                <Pagination
                                    defaultCurrent={1}
                                    current={page}
                                    pageSize={15}
                                    onChange={handlePageChange}
                                    total={data.totalDocs}
                                    showSizeChanger={false}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </BaseModal>
    )
}
