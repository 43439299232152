import { preventKeyDown } from "../../services/functions"

type BaseCheckboxType = {
    check: boolean
    setcheck: (e: boolean) => void
    label: string
    classProps?: string
    bold?: boolean
    disabled?: boolean
    prevent?: boolean
}

export default function BaseCheckbox(props: BaseCheckboxType) {
    return (
        <div className={`flex gap-8 mt-2 items-center justify-center px-2 ${props.classProps}`}>
            <input disabled={props.disabled}                 onKeyDown={props.prevent ? preventKeyDown : () => {}}
             type="checkbox" checked={props.check} onChange={() => props.setcheck(!props.check)} className="checkbox border-black" />
            <p className={`label-text ${props.bold && "font-medium"}`}>{props.label}</p>
        </div>
    )
}
