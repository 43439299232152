import { useContext, useEffect, useState } from "react"
import BaseModal from "../../../../components/layout/BaseModal"
import BillModalScreen1 from "./ModalBillScreen1"
import BillModalScreen2 from "./ModalBillScreen2"
import BillModalScreen3 from "./ModalBillScreen3"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../../components/common/Loading"
import ErrorPage from "../../../404/ErrorPage"
import { getSingleCustomerData } from "../../../Kunden/api/apiCalls"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { BillPost, PositionData } from "../../../../data/Types"
import dayjs from "dayjs"
import { useAddBillMutation } from "../../api/services"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../../services/functions"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { messageError } from "../../../../services/messages"
import { getPaginatedData } from "../../../api/apiCalls"
import BillModalScreen4 from "./ModalBillScreen4"

type AddBillModalType = {
    queryKey: string
}

export default function AddBillModal(props: AddBillModalType) {
    const [screen2, setscreen2] = useState(false)
    const [screen3, setscreen3] = useState(false)
    const [screen4, setscreen4] = useState(false)
    const [customer, setcustomer] = useState("")

    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const queryKeyAllCustomer = "customerDataToAddBill" + searchParams
    const {
        data: customerData,
        error: errorCustomer,
        isLoading: isLoadingCustomer,
    } = useQuery({
        queryKey: [queryKeyAllCustomer],
        // TODO set limit to 10 if its works
        queryFn: () => getPaginatedData({ pageType: "customer", page: 1, limit: 3, search: searchParams, archived: false, deleted: false }),
    })

    const queryKeySingleCustomer = "singleCustomerData" + customer
    const {
        data: singleCustomer,
        error: errorSingleCustomer,
        isLoading: isLoadingSingleCustomer,
    } = useQuery({
        queryKey: [queryKeySingleCustomer],
        queryFn: () => getSingleCustomerData(customer),
        enabled: !(customer === ""),
    })

    const [billType, setbillType] = useState("Rechnung")
    const [billCategorie, setbillCategorie] = useState("Hausmeister")
    const [billDue, setbillDue] = useState<string>("28")
    const [billDay, setbillDay] = useState<string>("28")
    const [manualReminderEnabled, setmanualReminderEnabled] = useState<boolean>(false)
    const [forceFirstPayreqSameMonth, setforceFirstPayreqSameMonth] = useState<boolean>(false)
    const [reminderStartOffsetInDays, setreminderStartOffsetInDays] = useState<string>("")
    const [reminderIntervalInDays, setreminderIntervalInDays] = useState<string>("")
    const [maxReminders, setmaxReminders] = useState<string>("")
    const [deliveryDate, setdeliveryDate] = useState("")
    const [additionalText, setadditionalText] = useState<string | undefined>()
    useEffect(() => {
        setobject(undefined)
    }, [customer])
    const [object, setobject] = useState<string>()
    const [clearInputDropDown, setclearInputDropDown] = useState(false)
    const [positions, setposition] = useState<PositionData[]>([
        {
            position_id: "1",
            description: "",
            tax: "",
            unit: "",
            amount: 1,
            singlePrice: 0,
        },
    ])
    const [documents, setdocuments] = useState<
        {
            title: ""
            fileUpload: File
            uploadedByUser: true
        }[]
    >()

    // clear input after closing the modal
    const clearInput = () => {
        setscreen2(false)
        setscreen3(false)
        setbillType("Rechnung")
        setbillDue("28")
        setbillDay("28")
        setforceFirstPayreqSameMonth(false)
        setdeliveryDate(dayjs().format())
        setdocuments([])
        setcustomer("")
        setobject("")
        setadditionalText(undefined)
        setdeliveryDate("")
        setreminderStartOffsetInDays("")
        setreminderIntervalInDays("")
        setmaxReminders("")
        setclearInputDropDown(true)
        setTimeout(() => setclearInputDropDown(false), 100)
        setposition([
            {
                position_id: "1",
                description: "",
                tax: "",
                unit: "",
                amount: 1,
                singlePrice: 0,
            },
        ])
        setsearchTerm("")
    }

    // all about creating the new bill
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const { mutate: addBill } = useAddBillMutation(queryClient, props.queryKey)

    const handleNewBill = () => {
        if (object) {
            const NewBill: BillPost = {
                date: Date(),
                customer_id: customer,
                object_id: object,
                positions: positions,
                billType: billType,
                billCategorie: billCategorie,
                billDue: Number(billDue),
                billDay: Number(billDay),
                serviceDate: dayjs(deliveryDate).format("DD.MM.YYYY"),
                additionalText: additionalText,
                forceFirstPayreqSameMonth: forceFirstPayreqSameMonth,
            }
            if (manualReminderEnabled) {
                NewBill.manualReminderEnabled = manualReminderEnabled
                NewBill.reminderStartOffsetInDays = Number(reminderStartOffsetInDays)
                NewBill.reminderIntervalInDays = Number(reminderIntervalInDays)
                NewBill.maxReminders = Number(maxReminders)
            }
            console.log(NewBill)
            const formData = new FormData()
            formData.append("bill", JSON.stringify(NewBill))
            documents?.forEach((doc: any) => {
                if (doc) {
                    formData.append("additionalDocuments", doc)
                }
            })
            addBill(formData)
            clearInput()
            modalCon?.trigger(0)
        } else {
            messageError("noteError", "Fehlgeschlagen!", "Es ist kein Objekt zugeordnet. Bitte diesen Fehler dem Adiminstrator melden.")
        }
    }

    return (
        <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
            <BaseModal
                title={"Neue Rechnung erstellen"}
                modalId={1}
                bottomRow={
                    <div className="flex justify-between items-center">
                        <button
                            onKeyDown={() => preventKeyDown}
                            disabled={!screen2}
                            className="underline text-sm"
                            onClick={() => {
                                if (screen2 && !screen3 && !screen4) {
                                    setscreen2(false)
                                } else if (screen2 && screen3 && !screen4) {
                                    setscreen3(false)
                                } else if (screen2 && screen3 && screen4) {
                                    setscreen4(false)
                                }
                            }}>
                            zurück
                        </button>
                        {screen4 ? (
                            <button onKeyDown={() => preventKeyDown} className={`btn btn-success`} onClick={handleNewBill}>
                                Abschicken
                            </button>
                        ) : screen3 ? (
                            <button onClick={() => setscreen4(true)} className="btn bg-blue-300 border-none hover:bg-blue-400">
                                Weiter
                            </button>
                        ) : screen2 ? (
                            <button
                                onKeyDown={() => preventKeyDown}
                                className="btn bg-blue-300 border-none hover:bg-blue-400"
                                disabled={
                                    positions.some((obj) => {
                                        return Object.values(obj).some((wert) => wert === "" || wert === 0)
                                    }) || positions.length === 0
                                }
                                onClick={() => {
                                    setscreen3(true)
                                }}>
                                Weiter
                            </button>
                        ) : (
                            <button
                                onKeyDown={() => preventKeyDown}
                                className="btn bg-blue-300 border-none hover:bg-blue-400"
                                disabled={
                                    customer === "" ||
                                    object === "" ||
                                    deliveryDate === "" ||
                                    (manualReminderEnabled &&
                                        (reminderIntervalInDays === "" || reminderStartOffsetInDays === "" || maxReminders === ""))
                                }
                                onClick={() => {
                                    console.log(deliveryDate)
                                    setscreen2(true)
                                }}>
                                Weiter
                            </button>
                        )}
                    </div>
                }>
                <div className="overflow-hidden relative h-full w-full">
                    {isLoadingSingleCustomer && <Loading />}
                    {errorSingleCustomer && <ErrorPage />}
                    {!errorSingleCustomer && (
                        <>
                            <BillModalScreen1
                                billCategorie={billCategorie}
                                forceFirstPayreqSameMonth={forceFirstPayreqSameMonth}
                                setforceFirstPayreqSameMonth={setforceFirstPayreqSameMonth}
                                setbillCategorie={setbillCategorie}
                                visible={true}
                                objectArray={singleCustomer?.objects}
                                customerArray={customerData?.docs
                                    ?.filter((item: { archived: boolean }) => !item.archived)
                                    .filter((item: { softDeleteAfter?: Date }) => !item.softDeleteAfter)}
                                customerId={customer}
                                objectId={object}
                                setcustomer={setcustomer}
                                setobject={setobject}
                                billType={billType}
                                setbillType={setbillType}
                                setbillDue={setbillDue}
                                setbillDay={setbillDay}
                                billDue={billDue}
                                billDay={billDay}
                                deliveryDate={deliveryDate}
                                setdeliveryDate={setdeliveryDate}
                                clearInputDropDown={clearInputDropDown}
                                manualReminderEnabled={manualReminderEnabled}
                                reminderStartOffsetInDays={reminderStartOffsetInDays}
                                reminderIntervalInDays={reminderIntervalInDays}
                                maxReminders={maxReminders}
                                setmanualReminderEnabled={setmanualReminderEnabled}
                                setreminderStartOffsetInDays={setreminderStartOffsetInDays}
                                setreminderIntervalInDays={setreminderIntervalInDays}
                                setmaxReminders={setmaxReminders}
                                additionalText={additionalText}
                                setadditionalText={setadditionalText}
                                searchTerm={searchTerm}
                                setsearchTerm={setsearchTerm}
                                isLoadingCustomer={isLoadingCustomer}
                                errorCustomer={errorCustomer}
                            />
                            <BillModalScreen2 visible={screen2} position={positions} setpositions={setposition} />
                            <BillModalScreen3 visible={screen3} documents={documents} setdocuments={setdocuments} />
                            <BillModalScreen4
                                visible={screen4}
                                data={{
                                    billCategorie: billCategorie,
                                    billDay: billDay,
                                    customer: singleCustomer,
                                    billDue: billDue,
                                    billType: billType,
                                    positions: positions,
                                    manualReminderEnabled: manualReminderEnabled,
                                    forceFirstPayreqSameMonth: forceFirstPayreqSameMonth,
                                    reminderStartOffsetInDays: reminderStartOffsetInDays,
                                    reminderIntervalInDays: reminderIntervalInDays,
                                    maxReminders: maxReminders,
                                    deliveryDate: deliveryDate,
                                    additionalText: additionalText,
                                    object: singleCustomer?.objects?.find((item: { _id: string | undefined }) => item._id === object),
                                }}
                            />
                        </>
                    )}
                </div>
            </BaseModal>
        </RenderWhenAuthorized>
    )
}
