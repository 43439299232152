import TableRowDetailPage from "../../../components/detailBillPage/TableRowDetailPage"

type OfferDetailOfferTableType = {
    data: any
}

export default function OfferDetailOfferTable(props: OfferDetailOfferTableType) {
    return (
        <div className="mt-4 w-full h-fit max-h-[56vh] rounded-default shadow-lg bg-white p-4 flex flex-col">
            <div className="flex flex-row justify-end border-black border-b-2 pb-2 gap-4 pr-8">
                <p>Netto: {props.data.totalNetto.toFixed(2).toString().replace(".", ",")} €</p>
                <div>|</div>
                <h3 className="tracking-tight font-semibold">Brutto: {props.data.totalBrutto.toFixed(2).toString().replace(".", ",")} €</h3>
            </div>
            <div className="overflow-x-hidden mt-2">
                <table className="table table-sm table-pin-rows">
                    <thead>
                        <tr className="h-10 border-none">
                            <th className="">Position</th>
                            <th className="">Beschreibung</th>
                            <th className="text-center">Menge</th>
                            <th className="text-center">Einheit</th>
                            <th className="text-center">Einzelpreis</th>
                            <th className="text-center">Abrechnung</th>
                        </tr>
                    </thead>
                    <tbody className="overflow-y-scroll">
                        {props.data?.positions.map((item: any, index: number) => (
                            <TableRowDetailPage
                                key={index}
                                pos={(index + 1).toString()}
                                description={item.description}
                                descriptionDetail={item.descriptionSmall}
                                amount={item.amount}
                                unit={item.unit}
                                singleprice={item.singlePrice}
                                tax={item.tax ?? 99}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
