import { useContext } from "react"
import ObjectDetailPartLeftCustomer from "./ObjectDetailPartLeftCustomer"
import ObjectDetailPartLeftList from "./ObjectDetailPartLeftList"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import BaseImageUpload from "../../components/ImageUpload"
import { ReactComponent as QRCODE } from "../../../../assets/QRCodeICON.svg"

type ObjectDetailPartLeftType = {
    id?: string
    name?: string
    s3RefIsExisting: boolean
    lvdoesnotexist: boolean
    obvdoesexist: boolean
    objectsOffer: {
        nr: string | number | undefined
        _id: string
    }[]
    objectsBill: {
        nr: string | number | undefined
        billType: string
        _id: string
    }[]
    employeeData?: {
        first_name: string
        last_name: string
        _id: string
    }[]
    startScanQrCode: () => void
}

export default function ObjectDetailPartLeft(props: ObjectDetailPartLeftType) {
    const modalCon = useContext(ModalContext)
    return (
        <div className="hidden md:flex flex-col w-full bg-white rounded-default shadow-lg overflow-y-scroll h-[80vh] p-4">
            <BaseImageUpload s3RefIsExisting={props.s3RefIsExisting} objectId={props.id ?? ""} />
            <ObjectDetailPartLeftCustomer name={props.name} />
            {/* OPEN OFFERS */}
            <ObjectDetailPartLeftList
                headline="Gestellte Angeobte:"
                singular="Angebot"
                newtext="Neues Angebot"
                linknew="/buchhaltung/angebote"
                link="/buchhaltung/angebote/"
                objectListItems={props.objectsOffer}
            />
            {/* OPEN BILLS */}
            <ObjectDetailPartLeftList
                headline="Gestellte Rechnungen:"
                newtext="Neue Rechnung"
                linknew="/buchhaltung/rechnungen"
                link="/buchhaltung/rechnung/"
                objectListItems={props.objectsBill}
            />
            {/* EMPLOYEE */}
            {/* NOTE -> currently no connection between employees and objects */}
            {/* NOTE -> thats why its disabled */}
            {/* <ObjectDetailPartLeftList
                headline="Mitarbeiter:"
                singular="Mitarbeiter"
                newtext="Mitarbeiter hinzufügen"
                linknew="/mitarbeiter"
                link="/mitarbeiter/"
                objectListItems={props.employeeData ?? []}
            /> */}
            {/* NOTE -> currently disabled bc no function in prod */}
            <div className="flex flex-wrap justify-end w-full gap-2 p-2">
                <button className="btn btn-outline self-start " onClick={() => modalCon?.trigger(8)}>
                    <QRCODE className="w-8 h-8" />
                </button>
                <button className="btn btn-outline self-start " onClick={() => modalCon?.trigger(5)}>
                    Leistungsverezichnis
                </button>
                {props.obvdoesexist && (
                    <button className="btn btn-outline self-start" disabled={props.lvdoesnotexist} onClick={() => modalCon?.trigger(7)}>
                        Objektbetreuungsvertrag
                    </button>
                )}
                <button
                    className="btn btn-primary btn-outline"
                    onClick={() => {
                        modalCon?.trigger(9)
                        props.startScanQrCode()
                    }}>
                    Scan QR Code
                </button>
            </div>
        </div>
    )
}
