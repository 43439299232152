import dayjs from "dayjs"
import { motion } from "framer-motion"

type ModalAddBillOrOfferScreen4Type = {
    data: any // TODO
    visible: boolean
}

export default function BillModalScreen4(props: ModalAddBillOrOfferScreen4Type) {
    return (
        <motion.div
            initial={{
                x: "100%",
            }}
            animate={{
                x: props.visible ? 0 : "100%",
            }}
            transition={{
                type: "ease",
            }}
            className="absolute top-0 left-0 w-full h-full pl-3 z-30 bg-white pb-2 overflow-y-scroll">
            <h3 className="font-semibold underline py-8">Kontrollen Sie Ihre Angaben: </h3>
            {props.data.billType && (
                <p>
                    <span className="font-semibold pr-2">Rechnungstyp:</span>
                    {props.data.billType}
                </p>
            )}
            {props.data.customer && (
                <p>
                    <span className="font-semibold pr-2">Kunde:</span>
                    {props.data?.customer.customer.customer?.name}
                </p>
            )}
            {props.data.customer && (
                <p>
                    <span className="font-semibold pr-2">Verwaltung:</span>
                    {props.data?.customer.customer.administrator?.name}
                </p>
            )}
            {props.data.object && (
                <p>
                    <span className="font-semibold pr-2">Objekt:</span>
                    {props.data.object.adress.street} {props.data.object.adress.nr}, {props.data.object.adress.plz} {props.data.object.adress.city}
                </p>
            )}
            {props.data.billCategorie && (
                <p>
                    <span className="font-semibold pr-2">Rechnungskategorie:</span>
                    {props.data.billCategorie}
                </p>
            )}
            {props.data.billDay && props.data.billType !== "Rechnung" && (
                <p>
                    <span className="font-semibold pr-2">Zahltag des Monats:</span>
                    {props.data.billDay}
                </p>
            )}
            {props.data.billDue && (
                <p>
                    <span className="font-semibold pr-2">Fällig nach: </span>
                    {props.data.billDue} Tagen
                </p>
            )}
            {props.data.deliveryDate && (
                <p>
                    <span className="font-semibold pr-2">Leistungsdatum:</span>

                    {dayjs(props.data.deliveryDate).format("DD.MM.YYYY")}
                </p>
            )}
            {props.data.reminderStartOffsetInDays && props.data.manualReminderEnabled && (
                <p>
                    <span className="font-semibold pr-2">Nach wie vielen Tagen wird gemahnt: </span>
                    {props.data.reminderStartOffsetInDays}
                </p>
            )}
            {props.data.reminderIntervalInDays && props.data.manualReminderEnabled && (
                <p>
                    <span className="font-semibold pr-2">In welchem Abstand wird gemahnt:</span>
                    {props.data.reminderIntervalInDays} Tage
                </p>
            )}
            {props.data.maxReminders && props.data.manualReminderEnabled && (
                <p>
                    <span className="font-semibold pr-2">Wie viele Mahnungen werden verschickt: </span>
                    {props.data.maxReminders}
                </p>
            )}
            {props.data.additionalText && (
                <p>
                    <span className="font-semibold pr-2">Zusatztext:</span>
                    {props.data.additionalText}
                </p>
            )}
            <p className="font-semibold ">Positionen:</p>
            <div className="flex flex-col gap-2 pl-4">
                {props.data.positions?.map((item: any) => (
                    <div className="flex flex-row gap-4">
                        <p>{item.description}</p>
                        <p>|</p>
                        <p>Steuern: {item.tax} %</p>
                        <p>|</p>
                        <p>
                            {item.amount} x {item.unit} für {Number(item.singlePrice).toFixed(2)} €
                        </p>
                    </div>
                ))}
            </div>
            {props.data.forceFirstPayreqSameMonth && <p>Es wird eine vergangene Zahlungsanforderung für diesen Monat erstellt</p>}
        </motion.div>
    )
}
