import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { InfoElementType } from "../../Objekte/types/types"
import { CustomerCardType } from "../types/Types"
import { ReactComponent as RESTORE } from "../../../assets/RefreshICON.svg"
import { ReactComponent as MAPPIN } from "../../../assets/MapPinICON.svg"
import { ReactComponent as HOUSE } from "../../../assets/HouseICON.svg"
import { ReactComponent as ARCHIVE } from "../../../assets/ArchiveICON.svg"
import { ReactComponent as UNARCHIVE } from "../../../assets/UnArchiveICON.svg"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"
// import { ReactComponent as TASK } from "../../../assets/"
import { ReactComponent as EURO } from "../../../assets/EuroICON.svg"

export default function CustomerCard(props: CustomerCardType) {
    return (
        <div
            className={`w-full max-h-64 h-fit rounded-default shadow-lg flex flex-col px-4 pt-4 pb-6 bg-white relative ${
                props.archived && "text-gray-400 opacity-60"
            }`}>
            {props.deleted && <p className="absolute top-28 right-20 rotate-12 font-bold text-black/30 opacity-50 scale-150">Gelöscht</p>}
            <h3 title={props.name + " | " + props.owner} className="border-b pb-1 text-lg 2xl:text-xl font-semibold px-2 truncate">
                {props.owner && props.owner !== props.name ? `${props.name}  |  ${props.owner}` : `${props.name}`}
            </h3>
            <p className="mb-4 pt-1 px-2 text-sm text-black/50">Debitor: {props.nr}</p>
            <InfoElement
                info={props.adress?.street + " " + props.adress?.nr + ", " + props.adress?.plz + ", " + props.adress?.city}
                icon={<MAPPIN className="w-6 h-6" />}
            />
            <InfoElement info={props.objects + " Objekte"} icon={<HOUSE className="w-6 h-6" />} />
            <InfoElement
                info={props.offers + " offene Angebote"}
                icon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                    </svg>
                }
            />
            <InfoElement info={props.bills + " gestellte Rechnungen"} icon={<EURO className="w-6 h-6" />} />

            <RenderWhenAuthorized requiresAll={[Roles.kunden_read_write, Roles.kunden_delete]}>
                <div className="flex flex-row justify-end gap-2 grow items-center z-10">
                    <p title="Archivieren" onClick={props.archiveFunc}>
                        {props.archived ? <UNARCHIVE className="w-6 h-6 cursor-pointer" /> : <ARCHIVE className="w-6 h-6 cursor-pointer" />}
                    </p>
                    {!props.deleted ? (
                        <p title="Löschen" onClick={props.deleteFunc}>
                            <DELETE className="w-6 h-6 cursor-pointer" />
                        </p>
                    ) : (
                        <p title="Wiederherstellen" onClick={props.restoreFunc}>
                            <RESTORE className="w-6 h-6 cursor-pointer" />
                        </p>
                    )}
                </div>
            </RenderWhenAuthorized>
            <div onClick={props.openFunc} className="h-full w-full absolute top-0 left-0 cursor-pointer z-0" />
        </div>
    )
}

function InfoElement({ info, icon }: InfoElementType) {
    return (
        <div className="flex flex-row pt-0.5 2xl:pt-1 pl-2">
            {icon}
            <p title={info} className="pl-2 line-clamp-1 text-sm 2xl:text-base">
                {info}
            </p>
        </div>
    )
}
